@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");
*,
*::before,
*::after {
  box-sizing: border-box;
}

ul[class],
ol[class] {
  padding: 0;
}

body,
h1,
h2,
h3,
h4,
p,
ul,
ol,
li,
figure,
figcaption,
blockquote,
dl,
dd {
  margin: 0;
}

body {
  min-height: 100vh;
  scroll-behavior: smooth;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
}

ul[class],
ol[class] {
  list-style: none;
}

a:not([class]) {
  text-decoration-skip-ink: auto;
}

img {
  max-width: 100%;
  display: block;
}

article > * + * {
  margin-top: 1em;
}

input,
button,
textarea,
select {
  font: inherit;
}

@media (prefers-reduced-motion: reduce) {
  * {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}
.template {
  display: flex;
  flex-direction: column;
  background-color: #328160;
  font-family: "Montserrat", sans-serif;
  text-align: center;
  color: #fff;
  min-height: 100vh;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
}

.step {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 360px;
  width: 100%;
}
.step__header {
  margin-top: auto;
}
.step__body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 24px;
  margin: auto;
}
.step:not(.step_active) {
  display: none;
}
.step__text_header {
  font-size: 16px;
  font-weight: 400;
  line-height: 25px;
}
.step__title {
  font-size: 28px;
  font-weight: 700;
  line-height: 39px;
  text-transform: uppercase;
}

.text-gray {
  color: rgba(255, 255, 255, 0.5);
}
.text-letter {
  font-size: 26px;
}
.text-number {
  font-size: 40px;
}

.checkbox {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 161px;
  width: 100%;
}
.checkbox__item {
  display: flex;
  width: 100%;
  height: 56px;
  padding: 16px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background-color: #4A9475;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  cursor: pointer;
  animation-name: pulse;
  animation-duration: 1.2s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  will-change: transform;
}
.checkbox__item__item:hover {
  border: 2px solid #fff;
}
.checkbox__item_checked, .checkbox__item_checked:hover, .checkbox__item:active {
  background-color: #fff;
  color: #000;
  box-shadow: none;
}
.checkbox__input {
  display: none;
}
.checkbox__label {
  display: flex;
  cursor: pointer;
  align-items: center;
}
.checkbox__label:before {
  content: "";
  display: block;
  width: 32px;
  height: 32px;
  margin-right: 12px;
  background: center/contain no-repeat;
}
.checkbox__label_something-new:before {
  background-image: url("https://i.gstatvb.com/687ef3bca69075f49ffac66919ffb3fe1711476579.rng.webp");
}
.checkbox__label_book:before {
  background-image: url("https://i.gstatvb.com/33f9a69819c3ea20230aa7f494001bbd1711476679.rng.webp");
}
.checkbox__label_games:before {
  background-image: url("https://i.gstatvb.com/a2ae485aec5d8def122c356846b2b98e1711476696.rng.webp");
}
.checkbox__label_partying:before {
  background-image: url("https://i.gstatvb.com/cb94a3d6fa0b95a6b23014657d585e5a1711476712.rng.webp");
}
.checkbox__label_shy:before {
  background-image: url("https://i.gstatvb.com/c94b6d8162bc00a9073ebc909f342d971711476728.rng.webp");
}
.checkbox__label_lighthearted:before {
  background-image: url("https://i.gstatvb.com/e9d801ecf1ae38b71ac74a33342aa67c1711476742.rng.webp");
}
.checkbox__label_outgoing:before {
  background-image: url("https://i.gstatvb.com/31a7c26ca590a9614dca17f9aafcb49d1711476754.rng.webp");
}
.checkbox__label_travels:before {
  background-image: url("https://i.gstatvb.com/94aef6f1c6276cac7697d4b78fd3f88f1711476766.rng.webp");
}
.checkbox__label_gym:before {
  background-image: url("https://i.gstatvb.com/bce03b738344191647b645351fb8942c1711476778.rng.webp");
}
.checkbox__label_communication:before {
  background-image: url("https://i.gstatvb.com/5b274bb9eb18a8d5967d7a0f85d044c41711476792.rng.webp");
}
.checkbox__label_friendship:before {
  background-image: url("https://i.gstatvb.com/1e2db396948988e57a259650420a37091711476804.rng.webp");
}
.checkbox__label_cooking:before {
  background-image: url("https://i.gstatvb.com/856f3f736f3122256a9add98d2dabc9c1711477512.rng.webp");
}

.loader {
  position: relative;
  display: flex;
  justify-content: center;
  width: 252px;
  height: 252px;
  transform: rotate(-90deg);
  margin-top: 58px;
}
.loader__text {
  font-size: 24px;
  font-weight: 400;
  line-height: 28px;
}
.loader__svg {
  position: relative;
  width: 100%;
  height: 100%;
}
.loader__circle, .loader__item {
  width: 100%;
  height: 100%;
  stroke-dasharray: 690;
  stroke-linecap: round;
}
.loader__circle_progress, .loader__item_progress {
  animation: animateCircle 4s linear forwards;
  stroke-dashoffset: 690;
}
.loader__circle {
  transform: translate(6px, 6px);
}
.loader__circle_progress {
  fill: none;
  stroke-width: 15;
  stroke: #6AEA8E;
  filter: none;
}
.loader__circle_background {
  fill: none;
  stroke-width: 15;
  stroke: #30462F;
  filter: none;
}
.loader__percent {
  position: absolute;
  top: 50%;
  left: 50%;
  display: flex;
  align-items: center;
  transform: translate(-50%, -50%) rotate(90deg);
  color: #fff;
  font-family: Verdana, sans-serif;
  font-size: 82px;
  font-weight: bold;
}
.loader__percent:after {
  content: "%";
  font-size: 32px;
}

.mirror-result {
  margin: auto;
  max-width: 312px;
  border-radius: 8px;
  border: 3px solid #FF9518;
  background: linear-gradient(0deg, rgba(252, 221, 57, 0.1) 0%, rgba(252, 221, 57, 0.1) 100%), #FFF;
  box-shadow: 0 12px 8px 0 rgba(0, 0, 0, 0.1);
  overflow: hidden;
}
.mirror-result__btn {
  padding: 0 18px;
}
.mirror-result__text {
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  color: #FF9518;
  font-weight: 800;
  line-height: 1;
}
.mirror-result__logo {
  display: flex;
  justify-content: center;
  margin: 32px auto 0;
}
.mirror-list {
  color: #171219;
  font-size: 16px;
  margin-top: 18px;
}
.mirror-list__item {
  display: flex;
  justify-content: center;
  align-items: center;
}
.mirror-list__item:before {
  width: 12px;
  height: 12px;
  margin-right: 6px;
  content: "";
  display: block;
  background-image: url("data:image/svg+xml,%3Csvg width='13' height='12' viewBox='0 0 13 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.66 8.76L9.89 4.53L9.05 3.69L5.66 7.08L3.95 5.37L3.11 6.21L5.66 8.76ZM6.5 12C5.67 12 4.89 11.8424 4.16 11.5272C3.43 11.212 2.795 10.7846 2.255 10.245C1.715 9.705 1.2876 9.07 0.9728 8.34C0.658 7.61 0.5004 6.83 0.5 6C0.5 5.17 0.6576 4.39 0.9728 3.66C1.288 2.93 1.7154 2.295 2.255 1.755C2.795 1.215 3.43 0.7876 4.16 0.4728C4.89 0.158 5.67 0.0004 6.5 0C7.33 0 8.11 0.1576 8.84 0.4728C9.57 0.788 10.205 1.2154 10.745 1.755C11.285 2.295 11.7126 2.93 12.0278 3.66C12.343 4.39 12.5004 5.17 12.5 6C12.5 6.83 12.3424 7.61 12.0272 8.34C11.712 9.07 11.2846 9.705 10.745 10.245C10.205 10.785 9.57 11.2126 8.84 11.5278C8.11 11.843 7.33 12.0004 6.5 12Z' fill='%232E432D'/%3E%3C/svg%3E%0A");
}
.mirror-list__item + .mirror-list__item {
  margin-top: 8px;
}
.mirror-tip {
  border: 1px solid #FF9518;
  background-color: #FF9518;
  box-shadow: 2px -2px 6px 0px rgba(23, 18, 25, 0.15);
  margin-top: 44px;
}
.mirror-tip__text {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #FFF;
  font-size: 10px;
  font-weight: 700;
  line-height: 13px;
}
.mirror-tip__text:before {
  content: "";
  display: block;
  width: 10px;
  height: 10px;
  margin-right: 8px;
  background: center/contain url("https://i.gstatvb.com/81415ef556c48b3f462a713bb2453c911711551816.rng.webp");
}

.btn_link {
  display: flex;
  width: 276px;
  height: 56px;
  padding: 18px 24px;
  justify-content: center;
  align-items: center;
  color: #FFF;
  font-family: Verdana, sans-serif;
  font-size: 16px;
  font-weight: 700;
  text-transform: capitalize;
  border-radius: 8px;
  background-color: #FF9518;
  text-decoration: none;
  margin-top: 32px;
}
.btn_link_link:hover, .btn_link_link:active, .btn_link_link:focus {
  background-color: #d67400;
}

@keyframes pulse {
  0% {
    transform: scaleX(1);
  }
  50% {
    transform: scale3d(1.05, 1.05, 1.05);
  }
  100% {
    transform: scaleX(1);
  }
}
@keyframes animateCircle {
  to {
    stroke-dashoffset: 0;
  }
}
@media screen and (max-width: 560px) {
  .step {
    justify-content: flex-start;
    padding-top: 24px;
    max-width: 100%;
  }
  .step__header {
    margin-top: 0;
  }
}
@supports (-webkit-touch-callout: none) {
  .template,
body {
    min-height: -webkit-stretch;
    min-height: stretch;
  }
}